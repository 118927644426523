import AppImage from '@theme/components/atom/AppImage'
import _ from 'lodash'

export default {
  components: {
    AppImage,
  },
  data() {
    return {
      image: null,
    }
  },
  mounted() {
    this.image =
      _.get(this.$store, `state.globals.block_contact.block_contact_image`) &&
      this.$store.state.globals.block_contact.block_contact_image.length > 0
        ? this.$store.state.globals.block_contact.block_contact_image[0]
        : null
  },
}
