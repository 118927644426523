import AppButton from '@theme/components/atom/AppButton'
import AppImage from '@theme/components/atom/AppImage'
import AppInput from '@theme/components/atom/AppInput'
import AppTitle from '@theme/components/atom/AppTitle'
import ContactBoxImage from '@theme/components/shop/ContactBoxImage'
import Modal from '@theme/components/utils/Modal'
import RequiredSign from '@theme/components/atom/RequiredSign'
import Wysiwyg from '@theme/components/utils/Wysiwyg'
import _ from 'lodash'

export default {
  components: {
    AppButton,
    AppImage,
    AppInput,
    AppTitle,
    ContactBoxImage,
    Modal,
    RequiredSign,
    Sticky: () => import('@theme/components/utils/Sticky'),
    Wysiwyg,
  },
  props: {
    alignContent: {
      type: String,
      default() {
        return this.$themeSettings.components.ContactBox.alignContent
      },
    },
    buttonText: {
      type: String,
      default() {
        return this.$t('ContactBox.button')
      },
    },
    contact: {
      type: Object,
      default() {
        return this.$store.state.globals.contact
      },
    },
    title: {
      type: String,
      default() {
        return this.$t('ContactBox.title')
      },
    },
    subTitle: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: 'd-lg-flex',
    },
    showButton: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.ContactBox.button.enable
      },
    },
    type: {
      type: String,
      default: 'box',
    },
    wysiwygContent: Object,
  },
  data() {
    return {
      hotLineOpeningHours: this.$te('ContactBox.hotLineOpeningHours')
        ? this.$t('ContactBox.hotLineOpeningHours')
        : null,
      issueFormInModal: null,
    }
  },
  created() {
    if (!this.hotLineOpeningHours && _.get(this.$store.state, 'globals.contact.openingHours')) {
      this.hotLineOpeningHours = this.$store.state.globals.contact.openingHours.replace('\n', '<br />')
    }
  },
  methods: {
    openModal() {
      import('@theme/components/shop/IssueFormInModal').then(component => {
        this.issueFormInModal = component.default
        this.$nextTick(() => {
          this.$refs.issueFormInModal.open()
        })
      })
    },
  },
}
